import * as EventAction from '../../redux/actions/EventAction';
import Api from '../../middleware/FetchMiddleware';
import { baseUrl } from '../../environments/Environment';
import { HttpAction } from '../../helpers/HttpHelper';

export const getListEventPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.getEventPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/event`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(EventAction.getEventPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.getEventPaginationFail(e));
        reject(e);
      });
  });
};

export const getListEventParticipantPagination = (dispatch, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.getEventParticipantPaginationRequest());
    Api.callApi(`${baseUrl}private/pos/event/participant`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(EventAction.getEventParticipantPaginationSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.getEventParticipantPaginationFail(e));
        reject(e);
      });
  });
};

export const getEventParticipantDetails = (dispatch, eventId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.getEventParticipantDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/event/${eventId}/participant`, HttpAction.Get, params, {})
      .then(response => {
        dispatch(EventAction.getEventParticipantDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.getEventParticipantDetailsFail(e));
        reject(e);
      });
  });
};

export const getEventDetails = (dispatch, eventId) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.getEventDetailsRequest());
    Api.callApi(`${baseUrl}private/pos/event/${eventId}`, HttpAction.Get, {})
      .then(response => {
        dispatch(EventAction.getEventDetailsSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.getEventDetailsFail(e));
        reject(e);
      });
  });
};

export const getEventStatistic = (dispatch, eventId) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.getEventStatisticRequest());
    Api.callApi(`${baseUrl}private/pos/event/${eventId}/stats`, HttpAction.Get, {})
      .then(response => {
        dispatch(EventAction.getEventStatisticSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.getEventStatisticFail(e));
        reject(e);
      });
  });
};

export const createSelfClaim = (dispatch, params, eventId) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.setSelfClaimRequest());
    Api.callApi(`${baseUrl}private/pos/event/${eventId}/claim`, HttpAction.Post, {}, params)
      .then(response => {
        dispatch(EventAction.setSelfClaimSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.setSelfClaimFail(e));
        reject(e);
      });
  });
};

export const createDelegateClaim = (dispatch, params, eventId) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.setDelegateClaimRequest());
    Api.callApi(
      `${baseUrl}private/pos/event/${eventId}/claim/multiple`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(EventAction.setDelegateClaimSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.setDelegateClaimFail(e));
        reject(e);
      });
  });
};

export const getEventParticipantData = (dispatch, eventId, invoiceItemId) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.getEventParticipantDataRequest());
    Api.callApi(
      `${baseUrl}private/pos/event/${eventId}/participant/${invoiceItemId}`,
      HttpAction.Get,
      {},
    )
      .then(response => {
        dispatch(EventAction.getEventParticipantDataSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.getEventParticipantDataFail(e));
        reject(e);
      });
  });
};

export const updateParticipantData = (dispatch, eventId, invoiceItemId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.updateParticipantDataRequest());
    Api.callApi(
      `${baseUrl}private/pos/event/${eventId}/participant/${invoiceItemId}`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(EventAction.updateParticipantDataSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.updateParticipantDataFail(e));
        reject(e);
      });
  });
};

export const resendEmail = (dispatch, eventId, invoiceItemId, params) => {
  return new Promise((resolve, reject) => {
    dispatch(EventAction.resendEmailRequest());
    Api.callApi(
      `${baseUrl}private/pos/event/${eventId}/participant/${invoiceItemId}/ticket-email`,
      HttpAction.Post,
      {},
      params,
    )
      .then(response => {
        dispatch(EventAction.resendEmailSuccess(response));
        return resolve(response);
      })
      .catch(e => {
        dispatch(EventAction.resendEmailFail(e));
        reject(e);
      });
  });
};
