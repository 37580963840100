/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import _ from 'lodash';
import { compose } from 'redux';
// component
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  NavigationStep,
  ButtonMain,
} from '../../../components/Index';
import { SkeletonDetails } from '../../../components/skeleton/Index';
import { StepCreateEvent, StepCreateTicket, StepCreateKitPack } from './views/Index';
// api
// helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
} from '../../../helpers/Index';
// style
import './EventAddStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const currentTime = CommonHelper.currentDate('HH:mm');

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialForm = {
  product_id: null,
  banner: null,
  name: '',
  category: null,
  status: null,
  description: '',
  venue: '',
  startDate: currentDate,
  endDate: endDateValue,
  startTime: '00:00',
  endTime: CommonHelper.objectCloning(currentTime),
  tickets: [],
};

const validationRules = {
  0: [
    'banner',
    'name',
    'category',
    'status',
    'description',
    'venue',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
  ],
  1: [],
  2: [],
};

class EventAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Event,
      PermissionPage.EventList,
      PermissionAccess.Add,
    );

    this.state = {
      current: 0,
      form: CommonHelper.objectCloning(initialForm),
      validationErrors: {},
      // isLoading: false,
      stepList: [
        { key: 'create_event', name: 'Create Event' },
        { key: 'create_ticket', name: 'Create Ticket' },
        { key: 'create_kit_pack', name: 'Create Kit Pack' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      pagesTitle: {
        helmet: 'FITCO | Event - Create Event',
        breadcrumbs: 'Create Event',
        card: 'Create Event',
      },
    };
  }

  componentDidMount() {
    const { form, pagesTitle } = this.state;
    const {
      match: { params },
    } = this.props;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(form),
      ...ifExistParamObject,
    };

    if (updateExitingForm.eventId) {
      this.setState({
        form: updateExitingForm,
        pagesTitle: {
          ...pagesTitle,
          helmet: 'FITCO | Event - Edit Event',
          breadcrumbs: 'Edit Event',
          card: 'Edit Event',
        },
      });
    }
  }

  handleFormChange = (key, value) => {
    const { form } = this.state;

    this.setState({
      form: { ...form, [key]: value },
    });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/event/list');
  };

  handleButtonClickNext = () => {
    if (this.validateCurrentStep()) {
      this.setState(prevState => ({ current: prevState.current + 1 }));
    } else {
      window.scrollTo(0, 0);
    }
  };

  handleButtonClickPrev = () => {
    const { current } = this.state;
    this.setState({ current: current - 1 });
  };

  validateCurrentStep = () => {
    const { current } = this.state;
    const errors = {};
    const requiredFields = validationRules[current] || [];

    requiredFields.forEach(field => {
      if (!this.state.form[field] || this.state.form[field] === '') {
        errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    this.setState({ validationErrors: errors });
    return Object.keys(errors).length === 0;
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, form, validationErrors } = this.state;
    const { handleFormChange } = this;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateTicket form={form} onChange={handleFormChange} />
          </Grid>
        );
        break;

      default:
        renderElement = (
          <Grid item lg md>
            <StepCreateEvent
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
    }

    return renderElement;
  }

  render() {
    const { toastInformation, current, stepList, pagesTitle } = this.state;
    const prevUrl = '/event/list';

    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetails />
      </div>
    );

    renderElement = (
      <div>
        <Helmet title={pagesTitle.helmet} />
        <div className="container-page-event-add scroll-container">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-calendar container-icon-prefix size-16" />
                      Event
                    </Link>
                    <label className="text-12" color="inherit">
                      {pagesTitle.breadcrumbs}
                    </label>
                  </Breadcrumbs>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <div className="container-main-card flex-column p-32">
                  <label className="text-16 text-bold text-rolling-stone mb-32">
                    {pagesTitle.card}
                  </label>
                  <NavigationStep currentValue={current} arrayLabel={stepList} />
                  <div className="mt-32">{this.renderActiveContent()}</div>
                  <Grid item lg={12} md={12} className="mt-36">
                    <Grid container justify="flex-end">
                      <Grid item lg={5} md={6}>
                        <Grid container justify="flex-end">
                          <Grid item lg={4} md={4} className="pl-8">
                            <ButtonMain
                              type="negative"
                              size="xl"
                              labelText="Cancel"
                              onClick={this.handleButtonClickCancel}
                            />
                          </Grid>
                          {current !== 0 ? (
                            <Grid item lg={4} md={4} className="pl-8">
                              <ButtonMain
                                type="ghost"
                                size="xl"
                                labelText="Prev"
                                onClick={this.handleButtonClickPrev}
                              />
                            </Grid>
                          ) : null}
                          <Grid item lg={4} md={4} className="pl-8">
                            <ButtonMain
                              type="primary"
                              size="xl"
                              labelText="Next"
                              onClick={this.handleButtonClickNext}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg md className="section-page-footer" />
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </div>
    );

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = ({}) => ({});

EventAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(EventAdd));
