/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import { Grid, Breadcrumbs, Link, FormControl, FormLabel } from '@material-ui/core';
import _ from 'lodash';
// Components
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  PrevStateValue,
  TextInput,
  SelectInputSearchMain,
  SkeletonMain,
  ButtonMain,
  SelectInputMain,
  CheckInput,
  LabelInput,
  UploadImage,
  UploadDocument,
  ButtonIconMain,
} from '../../../components/Index';
import { ModalSelfieScanner } from '../../user-management/users/components/Index';
// Api
import {
  getEventDetails,
  getEventParticipantDetails,
  createSelfClaim,
  createDelegateClaim,
} from '../../../services/api/EventApi';
import { resetSelfieImage } from '../../../redux/actions/UsersAction';
// Helpers
import { CommonHelper } from '../../../helpers/Index';
// Style
import './EventParticipantClaim.scss';
// Icons
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const claimTypeOption = [
  { value: 'self', name: 'Self Claim' },
  { value: 'delegate', name: 'Claim via Representative' },
];

const idTypeOption = [
  { value: 'ktp', name: 'KTP' },
  { value: 'sim', name: 'SIM' },
  { value: 'passport', name: 'Passport' },
];

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialForm = {
  claimType: claimTypeOption[0].value,
  selfiePhoto: null,
  declaration: false,
  name: '',
  email: '',
  phone: '',
  idType: idTypeOption[0].value,
  idNumber: '',
  imageBase64: null,
  documents: [],
};

const initialFilter = {
  search: null,
};

class EventParticipantClaimPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      usersReducer: { prevStateValue },
    } = props;

    const {
      match: { params },
    } = this.props;

    const updateExitingFilter = {
      ...CommonHelper.objectCloning(initialFilter),
      ...prevStateValue,
    };
    this.state = {
      eventId: CommonHelper.decryptObject(params.event_id).eventId,
      form: CommonHelper.objectCloning(initialForm),
      validation: {},
      filter: updateExitingFilter,
      page: 1,
      limit: 10,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} from ${total}`,
        pageSizeOptions: ['10', '20', '30', '40'],
      },
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      list: [],
      loading: false,
      isLoading: false,
      openModalSelfie: false,
      uploading: false,
    };
    this.searchDebounce = _.debounce(this.searchDebounce, 400);
  }

  componentDidMount() {
    this.getDetails();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.form.claimType !== this.state.form.claimType) {
      this.setState({
        form: {
          ...this.state.form,
          selfiePhoto: null,
          declaration: false,
          name: '',
          email: '',
          phone: '',
          idType: idTypeOption[0].value,
          idNumber: '',
          imageBase64: null,
          documents: [],
        },
      });
    }

    if (prevState.form.idType !== this.state.form.idType) {
      this.setState({
        form: {
          ...this.state.form,
          idNumber: '',
        },
      });
    }

    if (prevProps.usersReducer.setSelfieImage !== this.props.usersReducer.setSelfieImage) {
      this.setState({
        form: {
          ...this.state.form,
          selfiePhoto: this.props.usersReducer.setSelfieImage,
        },
      });
    }
  };

  componentWillUnmount() {
    this.props.onResetSelfieImage();
  }

  getDetails = async () => {
    const { eventDetails } = this.props;
    const { eventId } = this.state;

    try {
      const { data } = await eventDetails(eventId);
      if (data) {
        this.setState({
          loading: false,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        isLoading: false,
      });
    }
  };

  getListPagination = async () => {
    const { eventParticipantDetails } = this.props;
    const { eventId, limit, page, filter } = this.state;

    const param = {
      page,
      limit,
      search: filter.search,
    };

    this.setState({ loading: true });
    await eventParticipantDetails(eventId, param);
    this.setState({ loading: false });
  };

  handleChangeSearch = value => {
    const { filter } = this.state;

    this.setState(
      {
        filter: { ...filter, search: value },
        page: 1,
        pagination: { ...this.state.pagination, current: 1 },
      },
      () => {
        this.searchDebounce();
      },
    );
  };

  searchDebounce = () => {
    this.getListPagination();
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  handleSelectOption = value => {
    const {
      eventData: { participants },
    } = this.props;
    const { list } = this.state;
    const selectedData = participants.find(item => item.sales_invoice_item_id === value);
    const duplicateData = list.find(item => item.sales_invoice_item_id === value);

    if (duplicateData || !selectedData) {
      return this.setState(prevState => ({
        list: [...prevState.list],
        filter: { ...prevState.filter, search: null },
      }));
    }

    return this.setState(prevState => ({
      list: [...prevState.list, selectedData],
      filter: { ...prevState.filter, search: null },
    }));
  };

  handleButtonDelete = value => {
    const { list } = this.state;
    const updatedList = list.filter(item => item.sales_invoice_item_id !== value);
    this.setState({ list: updatedList });
  };

  handleTableChange = pagination => {
    this.setState(
      {
        limit: pagination.pageSize,
        pagination: {
          ...this.state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        page: pagination.current,
      },
      () => {
        this.getListPagination();
      },
    );
  };

  getValidation = () => {
    const errors = {};
    let requiredFields = ['selfiePhoto', 'list', 'declaration'];

    if (this.state.form.claimType === 'delegate') {
      requiredFields = [
        'selfiePhoto',
        'name',
        'email',
        'phone',
        'idNumber',
        'documents',
        'list',
        'declaration',
      ];
    }

    requiredFields.forEach(field => {
      let value = this.state.form[field];
      if (field === 'list') {
        value = this.state[field];
      }

      if (!value || value === null || value === '') {
        errors[field] = `Field is required`;
      }
    });
    this.setState({ validation: errors });
    return Object.keys(errors).length === 0;
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleButtonClickNext = async () => {
    const { createClaim, createClaimMultiple, onResetSelfieImage } = this.props;
    const { list, eventId, form } = this.state;
    const validation = this.getValidation();

    if (!validation) return;

    let payload = {
      selfie: form.selfiePhoto,
      identity_file: form.imageBase64,
      sales_invoice_item_id: list[0].sales_invoice_item_id,
    };

    this.setState({ isLoading: true });
    try {
      let response;
      if (form.claimType === 'self') {
        response = await createClaim(payload, eventId);
      } else if (form.claimType === 'delegate') {
        const { selfiePhoto, name, email, phone, idType, idNumber, imageBase64, documents } = form;
        const invoiceItem = list.map(v => v.sales_invoice_item_id);
        payload = {
          delegate: {
            selfie: selfiePhoto,
            name,
            email,
            phone,
            identity_type: idType,
            identity_number: idNumber,
            identity_file: imageBase64,
            procuration_letter: documents[0].url,
          },
          invoice_items: invoiceItem,
        };
        response = await createClaimMultiple(payload, eventId);
      }
      this.processMessage(response.messages[0], response.status);
    } catch (error) {
      this.processMessage(error.messages, 'error');
    } finally {
      onResetSelfieImage();
      this.setState({ isLoading: false, list: [], form: CommonHelper.objectCloning(initialForm) });
    }
  };

  handleFormChange = (key, value) => {
    const { onResetSelfieImage } = this.props;
    const { form, validation } = this.state;
    const updatedValidation = { ...validation };
    const updatedForm = { ...form };

    if (key === 'claimType') {
      onResetSelfieImage();
    }

    if (key === 'documents') {
      const converted = value.map((item, index) => ({
        uid: item.uid,
        order: index,
        name: item.name,
        status: 'done',
        url: item.url || item.base64file,
      }));
      updatedForm[key] = converted;
    } else {
      updatedForm[key] = value;

      if (value) {
        delete updatedValidation[key];
      } else {
        updatedValidation[key] = 'Field is required';
      }
    }

    this.setState({
      form: updatedForm,
      validation: updatedValidation,
    });
  };

  handleOpenModalSelfie = val => {
    this.setState({ openModalSelfie: val });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderClaimType = () => {
    const {
      form: { claimType, imageBase64 },
      uploading,
      validation,
    } = this.state;
    const {
      usersReducer: { setSelfieImage },
    } = this.props;
    const additionalInformation =
      claimType === 'self'
        ? 'Participant is claiming his/her own event kit.'
        : 'One or more participant is choosing a representative to claim the event kit on their behalf.';

    return (
      <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
        <label className="text-16 text-black text-semi-bold mb-24">Claim Type</label>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={3}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <SelectInputMain
                options={claimTypeOption}
                currentValue={claimType}
                onChange={value => {
                  this.setState({ list: [] });
                  this.handleFormChange('claimType', value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xl lg md style={{ display: 'flex', alignItems: 'center' }}>
            <label className="text-14 text-black text-regular mt-4">{additionalInformation}</label>
          </Grid>
        </Grid>
        {claimType === 'delegate' ? this.renderRepresentative() : null}
        {claimType === 'self' ? (
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="container-label-input form-label" required>
                  Photo with ID (KTP / Passport)
                </FormLabel>
                <button
                  type="button"
                  className="container-take-selfie-action"
                  onClick={() => this.handleOpenModalSelfie(true)}
                >
                  {setSelfieImage ? (
                    <div style={{ width: '100%', height: '200px' }}>
                      <img
                        src={setSelfieImage}
                        alt="selfie"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                      <a
                        className="text-12"
                        onClick={() => {
                          this.handleOpenModalSelfie(true);
                        }}
                      >
                        Change Image...
                      </a>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="ant-upload-text">Open Webcam</div>
                    </div>
                  )}
                </button>
                {validation.selfiePhoto && (
                  <label
                    className="text-12 text-regular mt-4 ml-16"
                    style={{ color: 'rgb(244, 67, 54)' }}
                  >
                    {validation.selfiePhoto}
                  </label>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Representative ID (KTP / SIM / Passport)" />
                <div className="container-image-action">
                  <UploadImage
                    onChange={item => this.handleFormChange('imageBase64', item)}
                    defaultValue={imageBase64}
                    maxSize={1}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  renderRepresentative = () => {
    const {
      usersReducer: { setSelfieImage },
    } = this.props;
    const {
      form: { name, email, phone, idType, idNumber, imageBase64, documents, uploading },
      validation,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xl={5} lg={5} md={5}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <LabelInput labelText="Representative Full Name" isRequired />
            <TextInput
              placeHolderText="Enter Full Name"
              onChange={value => this.handleFormChange('name', value)}
              currentValue={name}
              isError={!!validation.name}
              errorMessage={validation.name}
              size="md"
            />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Representative Email" isRequired />
                <TextInput
                  placeHolderText="Enter Email"
                  onChange={value => this.handleFormChange('email', value)}
                  currentValue={email}
                  isError={!!validation.email}
                  errorMessage={validation.email}
                  size="md"
                />
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Representative Phone" isRequired />
                <TextInput
                  placeHolderText="Enter Phone"
                  onChange={value => this.handleFormChange('phone', value)}
                  currentValue={phone}
                  numericOnly
                  isError={!!validation.phone}
                  errorMessage={validation.phone}
                  size="md"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="container-label-input form-label" required>
                  ID Type
                </FormLabel>
                <SelectInputMain
                  options={idTypeOption}
                  currentValue={idType}
                  onChange={value => this.handleFormChange('idType', value)}
                  validateStatus={!!validation.idType}
                  errorMessage={validation.idType}
                />
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Representative ID Number" isRequired />
                <TextInput
                  placeHolderText="Enter ID Number"
                  onChange={value => this.handleFormChange('idNumber', value)}
                  currentValue={idNumber}
                  isError={!!validation.idNumber}
                  errorMessage={validation.idNumber}
                  size="md"
                  numericOnly={idType === 'ktp' || idType === 'sim'}
                  maxLength={idType === 'ktp' || idType === 'sim' ? 16 : 10}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={7} lg={7} md={7}>
          <Grid container spacing={4}>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="label" className="container-label-input form-label" required>
                  Photo with ID (KTP / Passport)
                </FormLabel>
                <button
                  type="button"
                  className="container-take-selfie-action"
                  onClick={() => this.handleOpenModalSelfie(true)}
                >
                  {setSelfieImage ? (
                    <div style={{ width: '100%', height: '200px' }}>
                      <img
                        src={setSelfieImage}
                        alt="selfie"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                      <a
                        className="text-12"
                        onClick={() => {
                          this.handleOpenModalSelfie(true);
                        }}
                      >
                        Change Image...
                      </a>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div className="ant-upload-text">Open Webcam</div>
                    </div>
                  )}
                </button>
                {validation.selfiePhoto && (
                  <label
                    className="text-12 text-regular mt-4 ml-16"
                    style={{ color: 'rgb(244, 67, 54)' }}
                  >
                    {validation.selfiePhoto}
                  </label>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Representative ID (KTP / SIM / Passport)" />
                <div className="container-image-action">
                  <UploadImage
                    onChange={item => this.handleFormChange('imageBase64', item)}
                    defaultValue={imageBase64}
                    maxSize={1}
                    validateStatus={!!validation.imageBase64}
                    errorMessage={validation.imageBase64}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <LabelInput labelText="Procuration Letter (Surat Kuasa)" isRequired />
              <div className="container-image-action">
                <UploadDocument
                  labelText="Upload File"
                  onFileChange={item => this.handleFormChange('documents', item)}
                  fileType="pdf/img/doc"
                  size="md"
                  fileList={documents}
                  maxFiles={1}
                  maxSize={1}
                  validateStatus={!!validation.documents}
                  errorMessage={validation.documents}
                />
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderParticipant = () => {
    const { list } = this.state;

    return (
      <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
        <label className="text-16 text-black text-semi-bold mb-24">Participant Information</label>
        <div style={{ marginBottom: '24px' }}>{this.renderFilter()}</div>
        <Table
          rowKey={record => record.sales_invoice_item_id}
          columns={this.renderColumns()}
          dataSource={list}
          pagination={false}
          onChange={this.handleTableChange}
        />
      </Grid>
    );
  };

  renderDeclaration = () => {
    const {
      form: { declaration },
      validation,
    } = this.state;

    return (
      <Grid item lg md className="container-main-card flex-column p-32 flex-wrap-unset mb-32">
        <label className="text-16 text-black text-semi-bold mb-24">Declaration</label>
        <FormControl component="fieldset" fullWidth margin="normal">
          <CheckInput
            currentValue={declaration}
            colorCheck="primary"
            labelText={
              'I understand that race kit can only be claimed once and all the information specified above are correct and true.'
            }
            onCheck={value => this.handleFormChange('declaration', value)}
          />
        </FormControl>
        {validation.declaration ? (
          <label className="text-12 text-regular mt-4 ml-16" style={{ color: '#f44336' }}>
            {validation.declaration}
          </label>
        ) : null}
      </Grid>
    );
  };

  renderFilter = () => {
    const {
      eventData: { participants },
    } = this.props;
    const {
      filter,
      loading,
      form: { claimType },
      list,
    } = this.state;
    const ListCustom = CommonHelper.renameKeyName(participants, 'sales_invoice_item_id', 'value');

    return (
      <div className="flex-column">
        <Grid item xl={12} lg={12} md={12} className="row-filter">
          <SelectInputSearchMain
            currentValue={filter.search}
            placeholder={'Search participant by name, registration id and/or identity number'}
            options={ListCustom}
            loading={loading}
            isEventOption
            disabled={claimType === 'self' && list.length > 0}
            onSearch={item => {
              this.handleChangeSearch(item);
            }}
            onChange={item => {
              this.handleSelectOption(item);
            }}
          />
        </Grid>
      </div>
    );
  };

  renderColumns = () => {
    return [
      {
        title: 'No',
        dataIndex: 'id',
        key: 'id',
        render: (text, row, index) => index + 1,
      },
      {
        title: 'Participant Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'eKTP/Passport Number',
        dataIndex: 'identity_number',
        key: 'identity_number',
      },
      {
        title: 'Registration ID',
        dataIndex: 'registration_id',
        key: 'registration_id',
      },
      {
        title: 'Category',
        dataIndex: 'ticket_category',
        key: 'ticket_category',
      },
      {
        title: 'Kit Details',
        dataIndex: 'kit_category',
        key: 'kit_category',
        render: (text, row) => {
          return (
            <div className="flex-column">
              <label className="text-14 text-regular text-black wrapping-container second line wrapping-container-break mb-8">
                Size: {row.kit_category}
              </label>
              <label className="text-14 text-regular text-rolling-stone wrapping-container second line wrapping-container-break">
                BIB Name: {row.bib_name || '-'}
              </label>
            </div>
          );
        },
      },
      {
        align: 'center',
        render: (text, row) => {
          return (
            <ButtonIconMain
              icon="ic-ffo-bin"
              type="negative"
              size="sm"
              onClick={() => this.handleButtonDelete(row.sales_invoice_item_id)}
            />
          );
        },
      },
    ];
  };

  render() {
    const {
      match: { params },
      eventData: { details },
    } = this.props;
    const { toastInformation, isLoading, openModalSelfie } = this.state;
    const prevUrl = '/event/participant';

    let renderElement = <SkeletonMain />;

    renderElement = (
      <div>
        <Helmet title={`FITCO | Event Participants Claim Lists`} />
        <div className="container-page-participant-claim-create scroll-container-invisible">
          <div className="container-page-scrolling-area">
            <Grid container direction="column" className="flex-wrap-unset">
              <Grid item lg md sm className="section-page-header">
                <div className="breadcrumbs-section">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      className="text-12"
                      color="inherit"
                      href={prevUrl}
                      onClick={event => {
                        this.handleClick(event, prevUrl);
                      }}
                    >
                      <i className="ic-ffo-coupon container-icon-prefix size-16" />
                      Participant List
                    </Link>
                    <Link
                      className="text-12"
                      color="inherit"
                      href={`/event/participant/details/${params.event_id}`}
                      onClick={event => {
                        this.handleClick(event, `/event/participant/details/${params.event_id}`);
                      }}
                    >
                      Participant Detail
                    </Link>
                    <label className="text-12" color="inherit">
                      Claim Kit
                    </label>
                  </Breadcrumbs>
                </div>
                <div className="mt-24">
                  <label className="text-semi-bold text-16 text-mine-shaft">{details.name}</label>
                </div>
              </Grid>
              <Grid item lg md className="section-page-body">
                <Grid container direction="column" className="flex-wrap-unset">
                  {this.renderClaimType()}
                  {this.renderParticipant()}
                  {this.renderDeclaration()}
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} className="mb-18">
                <Grid container justify="flex-end">
                  <Grid item lg={5} md={6}>
                    <Grid container justify="flex-end">
                      <Grid item lg={4} md={4} className="pl-8">
                        <ButtonMain
                          type="negative"
                          size="xl"
                          labelText="Cancel"
                          isLoading={isLoading}
                          onClick={this.handleButtonClickCancel}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} className="pl-8">
                        <ButtonMain
                          type="primary"
                          size="xl"
                          labelText="Submit"
                          isLoading={isLoading}
                          onClick={this.handleButtonClickNext}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
        <ModalSelfieScanner
          bypassComparison
          isOpen={openModalSelfie}
          onClose={() => this.handleOpenModalSelfie(false)}
        />
      </div>
    );

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  eventDetails: eventId => getEventDetails(dispatch, eventId),
  eventParticipantDetails: (eventId, params) =>
    getEventParticipantDetails(dispatch, eventId, params),
  createClaim: (params, eventId) => createSelfClaim(dispatch, params, eventId),
  createClaimMultiple: (params, eventId) => createDelegateClaim(dispatch, params, eventId),
  onResetSelfieImage: () => dispatch(resetSelfieImage()),
});

const mapStateToProps = ({ usersReducer, eventData }) => ({
  usersReducer,
  eventData,
});

EventParticipantClaimPage.propTypes = {
  createClaim: PropTypes.func,
  createClaimMultiple: PropTypes.func,
  eventData: PropTypes.object,
  eventDetails: PropTypes.func,
  eventParticipantDetails: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  onResetSelfieImage: PropTypes.func,
  usersReducer: PropTypes.object,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages, PrevStateValue);

export default shell(core(EventParticipantClaimPage));
