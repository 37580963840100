import { constants } from '../reducers/UsersReducer';

export function loginSuccess(response) {
  return { type: constants.loginSuccess, payload: response.data };
}
export function loginFail(response) {
  return { type: constants.loginFail, payload: response.data };
}

export function reAuthenticationSuccess(response) {
  return { type: constants.reAuthenticationSuccess, payload: response.data };
}
export function reAuthenticationFail(response) {
  return { type: constants.reAuthenticationFail, payload: response.data };
}

export function setCurrentActiveMerchant(param) {
  return { type: constants.setCurrentActiveMerchant, payload: param };
}

export function resetActiveMerchant() {
  return { type: constants.resetActiveMerchant };
}
export function setPrevState(values) {
  return { type: constants.setPrevState, payload: values };
}

export function resetPrevState() {
  return { type: constants.resetPrevState };
}

export function setUserLocationCoordinate(param) {
  return { type: constants.setUserLocationCoordinate, payload: param };
}

export function uploadOcrSuccess(response) {
  return { type: constants.uploadOcrSuccess, payload: response.data };
}
export function uploadOcrFail(response) {
  return { type: constants.uploadOcrFail, payload: response.data };
}

export function registerNewUserRequest() {
  return { type: constants.registerNewUserRequest };
}
export function registerNewUserSuccess(response) {
  return { type: constants.registerNewUserSuccess, payload: response.data };
}
export function registerNewUserFail(response) {
  return { type: constants.registerNewUserFail, payload: response.data };
}

export function setIdentityImage(payload) {
  return { type: constants.setIdentityImage, payload };
}

export function setSelfieImage(payload) {
  return { type: constants.setSelfieImage, payload };
}
export function resetSelfieImage() {
  return { type: constants.resetSelfieImage };
}

export function setFaceSimilarity(payload) {
  return { type: constants.setFaceSimilarity, payload };
}

export function userCovidTestRequest() {
  return { type: constants.userCovidTestRequest };
}
export function userCovidTestSuccess(response) {
  return { type: constants.userCovidTestSuccess, payload: response.data };
}
export function userCovidTestFail(response) {
  return { type: constants.userCovidTestFail, payload: response.data };
}
