import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Spin } from 'antd';
import clsx from 'clsx';
// Style
import './SelectInputSearchMainStyle.scss';

const { Option } = Select;

const SelectInputSearchMain = props => {
  const {
    placeholder,
    currentValue,
    defaultValue,
    options,
    validateStatus,
    errorMessage,
    size,
    disabled,
    loading,
    isEventOption,
    onChange,
    onSearch,
  } = props;

  const handleItemChange = selectedValue => {
    onChange(selectedValue);
  };

  const handleSearch = val => {
    if (onSearch) onSearch(val);
  };

  const renderOpt = () => {
    if (loading) {
      return (
        <Option disabled key={'loading'} style={{ textAlign: 'center', margin: '20px 0px' }}>
          <Spin size="default" />
        </Option>
      );
    }

    if (options.length) {
      return options.map(item => {
        let name = item.name;
        if (isEventOption) {
          name = `${item.name} - Reg.ID: ${item.registration_id ? item.registration_id : null} ${
            item.claimed ? '(Claimed)' : ''
          }`;
        }
        return (
          <Option
            key={item.value}
            value={item.value}
            name={item.name}
            registration_id={item.registration_id || null}
            identity_number={item.identity_number || null}
            disabled={item.claimed}
            style={{ color: item.claimed ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)' }}
          >
            {name}
          </Option>
        );
      });
    }

    return null;
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Select
        className={`container-select-input-search-main ${clsx({ [`text-${size}`]: size })}`}
        placeholder={loading ? 'Loading...' : placeholder}
        filterOption={(input, option) => {
          const optionData = option.props;
          const inputLower = input.toLowerCase();
          const nameMatch = optionData.name && optionData.name.toLowerCase().includes(inputLower);
          const regIdMatch =
            optionData.registration_id &&
            optionData.registration_id.toLowerCase().includes(inputLower);
          const IdNumberMatch =
            optionData.identity_number &&
            optionData.identity_number.toLowerCase().includes(inputLower);
          return nameMatch || regIdMatch || IdNumberMatch;
        }}
        value={currentValue}
        defaultValue={defaultValue}
        size={size}
        showSearch
        disabled={disabled}
        loading={loading}
        style={{
          borderRadius: 20,
          width: '100%',
        }}
        onSearch={handleSearch}
        onChange={handleItemChange}
      >
        {renderOpt()}
      </Select>
    </Form.Item>
  );
};

SelectInputSearchMain.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  isEventOption: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputSearchMain.defaultProps = {
  size: 'middle',
};

export default SelectInputSearchMain;
